<template>
	<v-sheet class="pb-8">
		<div class="py-2 d-flex justify-space-between align-center">
			<v-text-field
				label="搜索工人/团队"
				v-model="search"
				outlined
				dense
				hide-details
				clearable
				clear-icon="mdi-close"
				prepend-inner-icon="mdi-magnify"
				@click:clear="clearAssigneeSearch"
				@input="onSearchAssignee"
				placeholder="输入工人或者团队名称"
				class="mx-1 my-2"
			></v-text-field>
			<v-spacer></v-spacer>
			<v-btn
				color="success"
				text
				elevation="0"
				@click="dlgAssignResource = true"
				v-if="user && user.role === 'admin'"
			>
				<v-icon left small>mdi-plus</v-icon>
				添加工人/团队
			</v-btn>
		</div>
		<v-divider></v-divider>
		<!--- loading overlay ---->
		<template v-if="loading">
			<div class="d-flex justify-center align-center flex-column mt-8 py-8">
				<v-progress-circular
				:size="140"
				:width="2"
				color="primary"
				indeterminate
				></v-progress-circular>
				<div class="body-1 mt-4">载入数据中</div>
			</div>
		</template>
		<template v-else>
			<template v-if="searchResult && searchResult.length">
				<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">
										名称
									</th>
									<th class="text-left">
										类别
									</th>
									<th class="text-left">
										邮箱
									</th>
									<th class="text-left">
										团队负责人
									</th>
									<th class="text-right" v-if="user && user.role === 'admin'">
										<div class="pr-4">功能</div>
									</th>
								</tr>
							</thead>
							<tbody>
							<tr v-for="assignee in searchResult" :key="assignee.name">
								<template v-if="assignee.hasOwnProperty('role')">
									<td>{{ assignee.name }}
									</td>
									<td>工人</td>
									<td>{{assignee.email}}</td>
									<td>{{assignee.name}}</td>
									<td class="text-right" v-if="user && user.role === 'admin'">
										<v-btn
											color="info"
											text
											small
											elevation="0"
											class="mr-2"
											:to="{ name: 'viewAccount', params: {id:assignee.id}}"
										>
											<v-icon small left>mdi-pencil</v-icon>
											编辑
										</v-btn>
										<v-btn
											color="error"
											text
											small
											elevation="0"
											@click="removeAssignee(assignee)"
										>
											<v-icon small left>mdi-trash-can-outline</v-icon>
											删除
										</v-btn>
								</td>
								</template>
								<template v-if="assignee.hasOwnProperty('owner')">
									<td>
										{{ assignee.name }}
									</td>
									<td>团队</td>
									<td>{{assignee.owner.email}}</td>
									<td>{{assignee.owner.name}}</td>
									<td class="text-right" v-if="user && user.role === 'admin'">
										<v-btn
											text
											small
											elevation="0"
											class="mr-2"
											color="info"
											:to="{ name: 'viewTeam', params: {id:assignee.id}}"
										>
											<v-icon small left>mdi-pencil</v-icon>
											编辑
										</v-btn>
										<v-btn
											color="error"
											small
											text
											elevation="0"
											@click="removeAssignee(assignee)"
									>
										<v-icon small left>mdi-trash-can-outline</v-icon>
										删除
									</v-btn>
								</td>
								</template>
							</tr>
							</tbody>
						</template>
					</v-simple-table>
			</template>
			<template v-else>
				<template v-if="assignees && assignees.length">
					<v-simple-table>
						<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">
									名称
								</th>
								<th class="text-left">
									类别
								</th>
								<th class="text-left">
									邮箱
								</th>
								<th class="text-left">
									团队负责人
								</th>
								<th class="text-right" v-if="user && user.role === 'admin'">
									<div class="pr-4">功能</div>
								</th>
							</tr>
						</thead>
						<tbody>
						<tr v-for="assignee in assignees" :key="assignee.name">						
							<template v-if="assignee.hasOwnProperty('role')">
								<td>{{ assignee.name }}
								</td>
								<td>工人</td>
								<td>{{assignee.email}}</td>
								<td>{{assignee.name}}</td>
								<td class="text-right" v-if="user && user.role === 'admin'">
									<v-btn
										color="info"
										text
										small
										elevation="0"
										class="mr-2"
										 :to="{ name: 'viewAccount', params: {id:assignee.id}}"
									>
										<v-icon small left>mdi-pencil</v-icon>
										编辑
									</v-btn>
									<v-btn
										color="error"
										text
										small
										elevation="0"
										@click="removeAssignee(assignee)"
									>
										<v-icon small left>mdi-trash-can-outline</v-icon>
										删除
									</v-btn>
							</td>
							</template>
							<template v-if="assignee.hasOwnProperty('owner')">
								<td>
									{{ assignee.name }}
								</td>
								<td>团队</td>
								<td>{{assignee.owner.email}}</td>
								<td>{{assignee.owner.name}}</td>
								<td class="text-right" v-if="user && user.role === 'admin'">
									<v-btn
										text
										small
										elevation="0"
										class="mr-2"
										color="info"
										 :to="{ name: 'viewTeam', params: {id:assignee.id}}"
									>
										<v-icon small left>mdi-pencil</v-icon>
										编辑
									</v-btn>
									<v-btn
										color="error"
										small
										text
										elevation="0"
										@click="removeAssignee(assignee)"
								>
									<v-icon small left>mdi-trash-can-outline</v-icon>
									删除
								</v-btn>
							</td>
							</template>

							</tr>
						</tbody>
						</template>
					</v-simple-table>
				</template>
				<template v-else>
					<v-alert
						class="ma-3"
						text
						dense
						type="info"
						>
							<div class="body-2">当前项目还没有添加任何工人或团队</div>
					</v-alert>
				</template>
			</template>
		</template>
		<v-dialog v-model="dlgAssignResource" max-width="680">
			<v-card>
				<v-card-title>添加工人或团队到项目</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="pa-0">
					<v-tabs v-model="tab">
						<v-tab  href="#tab-users">工人</v-tab>
						<v-tab  href="#tab-teams">团队</v-tab>
					</v-tabs>
					<v-divider></v-divider>
					<v-tabs-items v-model="tab">
						<v-tab-item value="tab-users">
							<user-list-tab :role="activeRole" :status="status"
								@on-filter-role="onFilterRole($event)"
								@on-filter-status="onFilterStatus($event)">
								<template v-slot="{ item }">
									<v-btn 
										color="success"
										elevation="0"
										small
										class="mr-2"
										@click="assignWorkerToProject(item)"
									>
										<v-icon small>
											mdi-plus
										</v-icon>
										添加
									</v-btn>
									<v-btn 
										color="info"
										elevation="0"
										small
										class="mr-2"
										:to="{ name: 'viewAccount', params: {id:item.id}}"
									>
										<v-icon small>
											mdi-pencil
										</v-icon>
											编辑
									</v-btn>
								</template>
							</user-list-tab>
						</v-tab-item>
						<v-tab-item  value="tab-teams">
						<work-team-list customActions>
							<template v-slot="{ item }">
								<v-btn 
										color="success"
										elevation="0"
										small
										class="mr-2"
										@click="assignTeamToProject(item)"
									>
										<v-icon small>
											mdi-plus
										</v-icon>
										添加
									</v-btn>
									<v-btn 
										color="info"
										elevation="0"
										small
										:to="{ name: 'viewTeam', params: {id:item.id}}"
									>
										<v-icon small>
											mdi-pencil
										</v-icon>
										编辑
									</v-btn>
							</template>
						</work-team-list>
						</v-tab-item>
					</v-tabs-items>

				</v-card-text>
			</v-card>
			<v-snackbar
				absolute
				top
				:color="snackbar.color"
				v-model="snackbar.model"
				>
				{{snackbar.message }}

				<template v-slot:action="{ attrs }">
					<v-btn
					text
					v-bind="attrs"
					@click="snackbar.model = false"
					>
					关闭
					</v-btn>
				</template>
			</v-snackbar>
		</v-dialog>
		<!--- Warning Message --->
		<v-dialog
			v-model="dialogWarnning"
			max-width="290"
			>
			<v-card>
				<v-card-title class="headline">
					确定要删除吗?
				</v-card-title>
				<v-card-text v-if="deleteAssignee">
					您确定要把<strong>{{deleteAssignee.name}}</strong>从项目中删除吗？
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="green darken-1"
					text
					@click="dialogWarnning = false"
				>
					取消
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="confirmAssigeeDeletion"
				>
					确定删除
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-sheet>
	
</template>
<script>
import ProjectServices from '../../services/Project';
import UserListTab from '../User/UserListTab.vue';
import WorkTeamList from '../Works/WorkTeamList.vue';
import { mapState } from 'vuex';

export default {
	name: 'projectAssignees',
	data: () => ({
		assignees: [],
		tab: 0,
		dlgAssignResource: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		dialogWarnning: false,
		loading: false,
		deleteAssignee: null,
		search: null,
		searchResult: null,
		activeRole: 'worker,contractor,team-leader,site-manager,admin',
		status: 0,
	}),
	components: {
		UserListTab,
		WorkTeamList
	},
	props: {
		projectId: {
			type: String | Number,
			required: true,
		}
	},
	watch: {

	},
	computed: {
		...mapState('user', ['user'],),
	},
	created(){
		this.updateAssigneeList();
	},
	methods: {
		async listProjectUsers() {
			try {
				 let response = await ProjectServices.listProjectUsers(this.projectId);
				 if(response.data.statusCode === 200){
					response.data.data.forEach(user=>{
						this.assignees.push(user)
					})
				}
			} catch (error){
				this.showError(error);
			}
		},
		async listProjectTeams() {
			try {
				 let response = await ProjectServices.listWorkTeamsInProject(this.projectId);
				 if(response.data.statusCode === 200){
					response.data.data.forEach(team=>{
						this.assignees.push(team)
					})
				}
			} catch (error){
				this.showError(error);
			}
		},
		async assignWorkerToProject(user){
			try {
				let response = await ProjectServices.addProjectUser(this.projectId, user.id);
				if(response.data.statusCode === 200){
					this.showSuccess(`${user.name}添加成功`);
					this.updateAssigneeList();
				}
			} catch(error) {
				this.showError(error);
			}
		},
		async assignTeamToProject(team) {
			try {
				let response = await ProjectServices.addWorkTeamToProject(this.projectId, team.id);
				if(response.data.statusCode === 200){
					this.showSuccess(`${team.name}添加成功`);
					this.updateAssigneeList();
				}
			} catch(error) {
				this.showError(error);
			}
		},
		async removeTeamFromProject(team){
			try {
				let response = await ProjectServices.removeWorkTeamFromProject(this.projectId, team.id);
				if(response.data.statusCode === 200){
					this.showSuccess(`${team.name}从项目删除成功`);
					this.updateAssigneeList();
				}
			} catch(error) {
				this.showError(error);
			}
		},
		async removeWorkerFromProject(user){
			try {
				let response = await ProjectServices.removeProjectUser(this.projectId, user.id);
				if(response.data.statusCode === 200){
					this.showSuccess(`${user.name}从项目删除成功`);
					this.updateAssigneeList();
				}
			} catch(error) {
				this.showError(error);
			}
		},
		updateAssigneeList(){
			this.loading = true;
			this.assignees = [];
			this.listProjectTeams();
			this.listProjectUsers();
			setTimeout(()=>{
				this.loading = false;
			}, 500)
		},
		confirmAssigeeDeletion(){
			if(this.deleteAssignee.owner){
				this.removeTeamFromProject(this.deleteAssignee);
			} else if(this.deleteAssignee.role){
				this.removeWorkerFromProject(this.deleteAssignee);
			}
			this.dialogWarnning = false;
		},
		removeAssignee(assignee){
			this.dialogWarnning = true;
			this.deleteAssignee = assignee;
		},
		showError(message){
			this.snackbar.message = message;
			this.snackbar.color = 'error';
			this.snackbar.model = true;
		},
		showSuccess(message){
			this.snackbar.message = message;
			this.snackbar.color = 'success';
			this.snackbar.model = true;
		},
		clearAssigneeSearch(){
			this.search = null
			this.searchResult = null;
		},
		onSearchAssignee(){
			const searchResult = this.assignees.filter((assignee)=>{
				return assignee.name.includes(this.search);
			})
			this.searchResult = searchResult
		},
		onFilterRole(roleName){
			this.activeRole = roleName;
		},
		onFilterStatus(status){
			this.status = status;
		},
	}
}

</script>