<template>
	<v-sheet v-if="project_id != null">
		<v-toolbar flat>
			<v-toolbar-title>项目信息</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				text
				@click="$router.go(-1)"
				elevation="0">
				返回上页</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<template v-if="user && user.role === 'admin'">
		<v-container>
			<div class="d-flex align-center">
				<div class="subtitle-2 mr-2" style="width:140px"></div>
				<div class="d-flex flex-wrap justify-end align-center flex-grow-1" >
					<v-btn small text color="info" @click="dialogEditProject = true"><v-icon left>mdi-pencil</v-icon>修改项目信息</v-btn>
				</div>
			</div>
		</v-container>
		<v-divider></v-divider>
		</template>
		<v-container v-if="project">
			<div class="d-flex pa-1 align-center mt-4">
				<div class="subtitle-2 mr-2" style="width:140px">项目名称:</div>
				<div class="body-2">{{project.name}}</div>
			</div>
			<div class="d-flex pa-1 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">建筑商:</div>
				<div class="body-2">{{project.builder_name}}</div>
			</div>
			<div class="d-flex pa-1 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">项目地址:</div>
				<div class="body-2">{{project.site_address}}</div>
			</div>
			<div class="d-flex pa-1 align-center">
				<div class="subtitle-2 mr-2" style="width:140px">项目开始日期:</div>
				<div class="body-2">{{project.start_date}}</div>
			</div>
			<div class="d-flex pa-1 align-center mb-4">
				<div class="subtitle-2 mr-2" style="width:140px">项目状态:</div>
				<div class="body-2">
					<v-chip
						small
						:color="statusColor"
						>
						{{getProjectStatusText(project.status)}}
					</v-chip>
				</div>
			</div>
		</v-container>
		<v-container class="pa-0">
			<v-divider></v-divider>
			<v-tabs v-model="tab">
				<v-tab v-for="tab in items" :key="tab.name">{{tab.name}}</v-tab>
			</v-tabs>
			<v-divider></v-divider>
			<!--- 日程记录 --->
			<template v-if="tab === 0">
				<project-timesheet-list
					:projectId="project_id"
					@on-error="onRequestError($event)"></project-timesheet-list>
			</template>
			<!--- 工头信息 --->
			<template v-if="tab === 1">
				<project-assignees :projectId="project_id" />
			</template>
			<!--- 数据分析 --->
			<div v-show="tab === 2">
				<v-tabs v-model="activeReport" show-arrows>
					<v-tab v-for="report in reports" :key="report.name">{{report.name}}</v-tab>
				</v-tabs>
				<v-divider></v-divider>
					<v-container v-if="project">
						<v-row>
							<v-col v-if="activeReport === 0">
								<report-day-labour-budget :projectId="project_id" :projectName="project.name" />
							</v-col>
							<v-col v-else-if="activeReport === 1">
								<report-project-budget  :projectId="project_id"  :projectName="project.name"/>
							</v-col>
							<v-col v-else-if="activeReport === 2">
								<report-project-work-team-summary :projectId="project_id" :projectName="project.name" />
							</v-col>
							<v-col v-else-if="activeReport === 3">
								<report-work-team-breakdown-summary :projectId="project_id"  :projectName="project.name"/>
							</v-col>
							<v-col v-else-if="activeReport === 4">
								<report-project-activity :projectId="project_id"  :projectName="project.name"/>
							</v-col>
						</v-row>
					</v-container>
			</div>
			<!--- 进度申请 --->
			<template v-if="tab === 3">
				<div class="pa-1 d-flex justify-space-between align-center">
					<v-subheader>项目进度表</v-subheader>
					<v-btn
						color="success"
						text
						elevation="0"
						@click="createProgressClaim"
						v-if="user && user.role === 'admin'"
					>
						<v-icon left small>mdi-plus</v-icon>
						添加进度表
					</v-btn>
				</div>
				<v-divider></v-divider>
				<template v-if="progress_advices && progress_advices.length">
					<v-data-table
						:headers="progressHeaders"
						:items="progress_advices"
						:items-per-page="itemsPerPage"
						hide-default-footer
						>
						<template v-slot:item.status="{item}">
								<template v-if="item.status == 0">
									<v-chip small color="info">可编辑</v-chip>
								</template>
								<template v-else-if="item.status == 1">
									<v-chip small color="primary">已递交</v-chip>
								</template>
								<template v-else>
									<v-chip small color="success">已审核</v-chip>
								</template>
						</template>
						<template v-slot:item.actions="{item}">
								<template v-if="user && user.role === 'admin'">
									<v-btn color="info" elevation="0" small class="mr-2" @click="editProgressClaimAdvice(item.id)">
										<v-icon small left>mdi-pencil</v-icon>
										编辑
									</v-btn>
									<template v-if="item.status == 2">
										<v-btn v-if="item.child_id === null" color="primary" elevation="0" small @click="duplicateProgressClaimAdvice(item.id)">
											<v-icon small left>mdi-content-duplicate</v-icon>
											复制
										</v-btn>
									</template>
									<template v-if="item.status == 0">
										<v-btn  color="error" elevation="0" small @click="deleteProgressClaimAdvice(item.id)">
											<v-icon small left>mdi-delete</v-icon>
											删除
										</v-btn>
									</template>
								</template>
								<template v-if="user && user.role === 'site-manager'">
									<v-btn color="info" elevation="0" small class="mr-2" @click="editProgressClaimAdvice(item.id)">
										
										<template v-if="item.status > 0">
											<v-icon small left>mdi-eye</v-icon>
											浏览
										</template>
										<template v-else>
											<v-icon small left>mdi-pencil</v-icon>
											编辑
										</template>
									</v-btn>
								</template>
						</template>
					</v-data-table>
				</template>
				<!---- 添加进度表 ----->
				<v-dialog v-model="dialogProgressForm" fullscreen>
					<project-progress-form 
						:projectId="project_id"
						:adviceId="active_advice_id"
						@on-status-update="getProjectProgressAdviceList"
					>
						<template v-slot:title>
							<v-btn
							icon
							dark
							@click="dialogProgressForm = false"
							>
							<v-icon>mdi-close</v-icon>
							</v-btn>
							<v-toolbar-title>编辑进度表 #{{ active_advice_id }}</v-toolbar-title>
						</template>
					</project-progress-form>
				</v-dialog>
			</template>

			<!--- 项目预算管理 --->
			<template v-if="tab === 4">
				<project-budget-list :projectId="project_id" />
			</template>
		</v-container>

		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		<!--- Edit Project Dialog ---->
		<v-dialog v-model="dialogEditProject" 
			max-width="480">
			<v-card>
				<v-card-title>
					修改项目信息
				</v-card-title>
				<v-divider></v-divider>
				<div class="pa-3">
					<project-form
						:projectData="project"
						isUpdate
						@on-submit-success="onProjectDetailUpdated"
					/>
				</div>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>

<script>
import ProjectServices from '../../services/Project';
import ProgressClaimAdviceService from '../../services/ProgressClaim';


import UserListTab from '../../components/User/UserListTab.vue';
import ProjectForm from '../../components/Project/ProjectForm';
import ProjectTimesheetList from '../../components/Project/ProjectTimesheetList';
import ProjectAssignees from '../../components/Project/ProjectAssignees.vue';
import ProjectProgressForm from '../../components/Project/ProjectProgressForm.vue';
import ProjectBudgetList from '../../components/Project/ProjectBudget.vue';

import { mapState } from 'vuex';

import ReportDayLabourBudget from '../../components/Reports/dayLabourBudgetReport.vue';
import ReportProjectBudget from '../../components/Reports/projectBudgetReport.vue';
import ReportProjectWorkTeamSummary from '../../components/Reports/projectWorkTeamSummary.vue';
import ReportWorkTeamBreakdownSummary from '../../components/Reports/projectWorkTeamBreakdownReport.vue';
import ReportProjectActivity from '../../components/Reports/projectActivityReport.vue';

export default {
	name: 'viewProject',
	components: {
		UserListTab,
		ProjectForm,
		ProjectTimesheetList,
		ProjectAssignees,
		ProjectProgressForm,
		ProjectBudgetList,
		ReportDayLabourBudget,
		ReportProjectBudget,
		ReportProjectWorkTeamSummary,
		ReportWorkTeamBreakdownSummary,
		ReportProjectActivity,
	},
	created(){
		this.project_id = parseInt(this.$route.params.id);
		if(this.project_id){
			this.getProjectDetail();
			this.getProjectProgressAdviceList();
		}
	},
	data: () => ({
		project_id: null,
		project: null,
		siteManagers: null,
		statusColor: null,
		tab: 0,
		items: [
			{'name': '日程记录' },
			{'name': '工人/团队' },
			{'name': '数据分析' },
			{'name': '进度申请' },
			{'name': '项目预算' }
		],
		dialogEditProject: false,
		dialogProgressForm: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
		reports : [
			{'name': '工种时间报表' },
			{'name': '项目预算比报表' },
			{'name': '项目团队用时' },
			{'name': '项目团队详情' },
			{'name': '项目汇总表' },
		],
		activeReport: 0,
		worker_id: null,
		selected_worker: null,
		report_types: [
			{
				name: '圆形图',
				value: 'pie'
			},
			{
				name: '条形图',
				value: 'bar'
			}
		],
		selected_type: [
			{
				name: '圆形图',
				value: 'pie'
			}
		],
		hide_report: true,
		progress_advices: [],
		active_advice_id: null,
		progressHeaders: [
			{ text: '编号', value: 'id',align: 'start', },
			{ text: '创建日期', value: 'created_at' },
			{ text: '上次更新日期', value: 'updated_at' },
			{ text: '状态', value: 'status', sortable: false, width: 120 },
	        { text: '功能', value: 'actions', sortable: false, width: 240 }
		],
		itemsPerPage:999
	}),
	computed: {
		...mapState('user', ['user'],),
	},
	watch: {
		selected_worker(){
			this.selected_type = null;
			this.hide_report = true;
		}
	},
	methods: {
		async getProjectDetail(){
			try {
				let response = await ProjectServices.getProjectInfo(this.project_id);
				if(response.status === 200){
					this.project = response.data.data;
					this.siteManagers = response.data.data.site_managers;
				}
			} catch(error){
				console.log(error)
			}
		},
		getProjectStatusText(code){
			if(parseInt(code) === 0){
				this.statusColor = 'info';
				return '项目进行中';
			} else if(parseInt(code) === 1){
				this.statusColor = 'success';
				return '项目已完工';
			} else if(parseInt(code) === 2){
				this.statusColor = 'primary';
				return '项目已封存';
			}
		},
		onProjectDetailUpdated(){
			setTimeout(()=>{
				this.dialogEditProject = false;
			}, 2000)
			this.getProjectDetail();
		},
		onRequestError(message){
			this.snackbar.message = message;
			this.snackbar.color = 'error';
			setTimeout(()=>{
				this.overlay = false;
				this.snackbar.model = true;
			}, 1000)
		},
		async getProjectProgressAdviceList(){
			try {
				let response = await ProgressClaimAdviceService.listProgressClaimAdvice(this.project_id);
				if(response.data.statusCode === 200){
					this.progress_advices = response.data.data;
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		},
		async createProgressClaim(){
			try {
				let response = await ProgressClaimAdviceService.createProgressClaimAdvice(this.project_id);
				if(response.data.statusCode === 200){		
					this.snackbar.message = `进度表${response.data.message}`;
					this.snackbar.color = 'success';
					this.getProjectProgressAdviceList();
					this.overlay = false;
					this.snackbar.model = true;
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		},
		async duplicateProgressClaimAdvice(adviceId){
			try {
				let response = await ProgressClaimAdviceService.duplicateClaimAdvice(adviceId);
				if(response.data.statusCode === 200){		
					this.snackbar.message = `进度表${response.data.message}`;
					this.snackbar.color = 'success';
					this.getProjectProgressAdviceList();
					this.overlay = false;
					this.snackbar.model = true;
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		},
		async deleteProgressClaimAdvice(adviceId){
			try {
				let response = await ProgressClaimAdviceService.deleteProgressClaimAdvice(adviceId);
				if(response.data.statusCode === 200){		
					this.snackbar.message = `进度表${response.data.message}`;
					this.snackbar.color = 'success';
					this.getProjectProgressAdviceList();
					this.overlay = false;
					this.snackbar.model = true;
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		},
		editProgressClaimAdvice(adviceId){
			this.dialogProgressForm = true;
			this.active_advice_id = adviceId;
		}
	}
}
</script>
