import Api from '@/services/Api';

export default {
	getBudgetList(projectId){
		return Api().get(`/project/${projectId}/budget`);
	},
	createBudget(projectId, body){
		return Api().post(`/project/${projectId}/budget`, JSON.stringify(body));
	},
	getBudgetDetail(projectBudgetId){
		return Api().get(`/project/budget/${projectBudgetId}`);
	},
	deleteProjectBudget(projectBudgetId) {
		return Api().delete(`/project/budget/${projectBudgetId}`);
	},
	updateProjectBudget(projectBudgetId, body){
		return Api().put(`/project/budget/${projectBudgetId}`, JSON.stringify(body));
	}
}