<template>
	<div>
		<v-form
			ref="form"
			class="pa-3"
			v-model="valid"
			lazy-validation
			@submit.prevent="onFormSubmit"
		>
			<v-text-field
				v-model="name"
				:counter="3"
				outlined
				:rules="nameRules"
				label="项目名称"
				required
			></v-text-field>
			<v-text-field
				v-model="builder_name"
				:rules="requiredRules"
				outlined
				label="建筑商"
				required
			></v-text-field>
			<v-text-field
				v-model="site_address"
				:rules="requiredRules"
				outlined
				label="项目地址"
				required
			></v-text-field>
			<!---
			<v-text-field
				v-model="budget_hours"
				:rules="budgetRules"
				type="number"
				outlined
				label="项目预算小时"
				required
			></v-text-field>
			--->
			<v-text-field
				:value="computedDateFormattedDatefns"
				clearable
				:rules="requiredRules"
				label="项目开始日期"
				outlined
				readonly
				required
				@click="dateMenu = true"
				@click:clear="start_date = null"
			></v-text-field>
			<v-dialog v-model="dateMenu" max-width="290">
				<v-date-picker
					v-model="start_date"
					:first-day-of-week="0"
					show-current
					locale="zh-cn"
					class="pb-6"
					@change="dateMenu = false"
				></v-date-picker>
			</v-dialog>
			<template v-if="isUpdate">
				<div class="subtitle-2 mb-2">选择项目状态:</div>
				<v-btn-toggle
					v-model="stauts"
					color="primary"
					class="mb-2"
					mandatory
					style="width:100%"
					@change="onStatusUpdate($event)"
				>
				    <v-btn value="0" class="flex-grow-1">
						进行中
					</v-btn>
					<v-btn value="1"  class="flex-grow-1">
						已完工
					</v-btn>
					<v-btn value="2"  class="flex-grow-1">
						已封存
					</v-btn>
				</v-btn-toggle>
			</template>
			<v-divider class="my-2"></v-divider>
			<div class="d-flex justify-space-between mt-4">
				<v-btn
					color="error"
					text
					@click="reset"
					>
					重新填写
				</v-btn>
				<v-btn
					:disabled="!valid"
					color="success"
					elevation="0"
					@click="validate"
					type="submit"
				>
					{{submitText}}
				</v-btn>
			</div>
		</v-form>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import ProjectServices from '../../services/Project';
import {  parse, format } from 'date-fns';

export default {
	name: 'projectFrom',
	data: () => ({
		valid: true,
		name: '',
		nameRules: [
			v => !!v || '请输入项目名称',
			v => (v && v.length >= 3) || '项目名称至少3个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		budgetRules: [
			v => !!v || '必填内容',
			v => (v && !isNaN(v)) || `请输入数字。例如：30`,
		],
		builder_name: '',
		site_address: '',
		//budget_hours: null,
		start_date: '',
		start_date_actual: '',
		stauts: 0,
		project_id: null,
		dateMenu: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false
	}),
	props: {
		projectData: {
			type: Object,
			required: false,
		},
		isUpdate: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	watch: {
		start_date(val){
			this.start_date_actual = this.formatDate(val);
		}
	},
	computed: {
		computedDateFormattedDatefns () {
			return this.start_date ? this.formatDate(this.start_date) : ''
		},
		submitText(){
			return this.isUpdate ? '更新项目' : '添加项目';
		}
	},
	created(){
		if(this.isUpdate){
			this.name = this.projectData.name;
			this.builder_name = this.projectData.builder_name;
			this.site_address = this.projectData.site_address;
			this.project_id = this.projectData.id;

			//this.budget_hours = this.projectData.budget_hours;
			this.stauts = this.projectData.status;
			if(this.projectData.start_date !== null){
				let formatDate = parse(this.projectData.start_date, 'dd/MM/yyyy', new Date());
				this.start_date = format(formatDate, 'yyyy-MM-dd');
			}
		}
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.name,
				"builder_name": this.builder_name,
				"site_address": this.site_address,
				//"budget_hours": parseFloat(this.budget_hours),
				"start_date": this.start_date_actual,
				"status": this.status
			}
			if(this.isUpdate){
				this.updateProject(body);
			} else {
				this.createProject(body);
			}
		},
		async updateProject(body){
			try {
				let response = await ProjectServices.updateProjectInfo(this.project_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `项目${response.data.message}`;
					this.snackbar.color = 'success';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
					}, 1000)
			}
		},
		async createProject(body){
			try {
				let response = await ProjectServices.createProject(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `项目${response.data.message}`;
					this.snackbar.color = 'success';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
						this.reset();
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
						this.reset();
					}, 1000)
			}
		},
		onStatusUpdate(event){
			this.status = event;
		}
	}
}
</script>
