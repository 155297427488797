<template>
	<v-card>
		<v-toolbar
			flat
			dark
			color="primary"
		>
			<slot name="title"></slot>
		</v-toolbar>
		<div class="d-flex justify-end pa-3" v-if="user && user.role === 'admin' && !isDisabled">
			<v-btn small color="primary" text elevation="0" @click="addNewBreakdown">
				<v-icon small left>mdi-table-large-plus</v-icon>添加新分类
			</v-btn>
		</div>
		<div style="overflow-x:scroll">
			<template v-if="breakdowns.length">
				<div class="breakdown" v-for="(breakdown, bIndex) in breakdowns" :key="breakdown.id">
					<div class="breakdown-heading d-flex align-center">
						<template v-if="user && user.role === 'admin' && !isDisabled">
						<div class="d-flex flex-grow-1">
							<input type="text"
								v-model="breakdown.name"
								class="form-input-field mr-2"
								/>
							<span class="error--text">*</span>
						</div>
						<div class="text-center" style="width:120px">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										v-bind="attrs"
										v-on="on"
										color="success" icon elevation="0" @click="addNewGroup(bIndex)">
										<v-icon small>mdi-table-plus</v-icon>
									</v-btn>
								</template>
								<span>添加新一组</span>
							</v-tooltip>

							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn 
										v-bind="attrs"
										v-on="on"
										color="error"
										icon elevation="0"
										@click="removeBreakdown(bIndex)">
										<v-icon small>mdi-table-large-remove</v-icon>
									</v-btn>
								</template>
								<span>删除整类</span>
							</v-tooltip>
						</div>
						</template>
						<template v-else>
							<div class="body-1 font-weight-medium">
								{{ breakdown.name }}
							</div>
						</template>
					</div>
					<table class="progressClaimTable">
						<thead>
							<tr>
								<th class="text-left">
									Description
								</th>
								<th class="text-left"  style="min-width:180px">
									% Complete
								</th>
								<template v-if="user && user.role === 'admin'">
									<th class="text-right">
										Total $ Value
									</th>
									<th class="text-right">
										$ Complete
									</th>
									<th class="text-left" style="width:120px" v-if=" !isDisabled">
									</th>
								</template>
							</tr>
						</thead>
							<tbody v-for="(group, gIndex) in breakdown.groups" :key="group.id">
								<tr class="grey lighten-5">
									<td colspan="2">

									<template v-if="user && user.role === 'admin' && !isDisabled">
										<div class="d-flex flex-grow-1">
											<input type="text"
												v-model="group.name"
												class="form-input-field mr-2"
												required
											/>
											<span class="error--text">*</span>
										</div>
									</template>
									<template v-else>
										<div class="body-2 font-weight-medium">
											{{ group.name }}
										</div>
									</template>
									</td>
									<template  v-if="user && user.role === 'admin'">
										<td></td>
										<td></td>
										<td class="text-center" v-if="!isDisabled">
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-btn 
														v-bind="attrs"
														v-on="on"
														color="success"
														icon elevation="0"
														@click="addGroupItem(bIndex, gIndex)">
														<v-icon small>mdi-table-row-plus-after</v-icon>
													</v-btn>
												</template>
												<span>添加一行</span>
											</v-tooltip>

											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
												<v-btn 
													v-bind="attrs"
													v-on="on"
													color="error"
													icon elevation="0"
													@click="removeGroup(bIndex, gIndex)">
													<v-icon small>mdi-table-remove</v-icon>
												</v-btn>
											</template>
											<span>删除整组</span>
											</v-tooltip>
										</td>
									</template>
								</tr>
								<tr v-for="(item, itemIndex) in group.items" :key="item.id">
									<td>
										<template v-if="user && user.role === 'admin' && !isDisabled">
											<div class="d-flex flex-grow-1">
												<input type="text"
													v-model="item.name"
													class="form-input-field mr-2"
												/>
												<span class="error--text">*</span>
											</div>
										</template>
										<template v-else>
											<div class="body-2">
												{{ item.name }}
											</div>
										</template>
									</td>
									<td>
										<v-slider
										v-model="item.completion_percentages"
										:label="item.completion_percentages + '%'"
										thumb-color="info"
										thumb-label
										hide-details
										:disabled="isDisabled"
										@end="updateManagerArray($event, item.id)"
										></v-slider>
										
									</td>
									<td class="text-right" v-if="user && user.role === 'admin'">
										<template v-if="!isDisabled">
										<input type="text"
											v-model="item.total_value"
											class="form-input-field"
										/>
										</template>
										<template v-else>
											{{ item.total_value | currencyFormat}}
										</template>
									</td>
									<td class="text-right"  v-if="user && user.role === 'admin'">
										{{ ( item.completion_percentages / 100 ) * item.total_value | currencyFormat}}
									</td>
									<td class="text-center"  v-if="user && user.role === 'admin' && !isDisabled">
										<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												color="error" icon elevation="0" @click="removeGroupItem(bIndex, gIndex, itemIndex)">
												<v-icon small>mdi-table-row-remove</v-icon>
											</v-btn>
										</template>
										<span>删除这一行</span>
									</v-tooltip>
									</td>
								</tr>
							</tbody>
					</table>
				</div>
			</template>
		</div>
		<v-divider class="mt-3"></v-divider>
		<div class="d-flex justify-space-between pa-3">
			<div>
				<v-btn
					v-if="advice_status === 0"
					color="info"
					elevation="0"
					@click="submitBreakdown(1)"
					>
					<v-icon left >mdi-upload</v-icon>
					上传进度表
				</v-btn>
				<v-btn
					v-if="advice_status === 1 && user && user.role === 'admin'"
					color="success"
					elevation="0"
					@click="dialogWarnning = true"
					>
					<v-icon left >mdi-check-circle</v-icon>
					完成审核进度表
				</v-btn>
			</div>
			<template v-if="isSavedButtonVisible">
					<v-btn
						color="success"
						elevation="0"
						@click="updateBreakdown"
						>
						<v-icon left >mdi-content-save</v-icon>
						保存进度表
					</v-btn>
			</template>
		</div>

		<v-dialog
			v-model="dialogWarnning"
			max-width="340"
			>
			<v-card>
				<v-card-title class="headline">
					完成进度表审核?
				</v-card-title>
				<v-card-text>
					<br />
					您确定要完成并通过这一期的项目进度表的审核吗？<br />
					<strong>进度表通过审核后您将无法继续编辑。</strong>
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="error darken-1"
					text
					@click="dialogWarnning = false"
				>
					取消
				</v-btn>
				<v-btn
					color="success"
					elevation="0"
					@click="submitBreakdown(2)"
				>
					<v-icon left >mdi-check-circle</v-icon>
					通过审核
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
	</v-card>
</template>

<script>
import ProgressClaimAdviceService from '../../services/ProgressClaim';
import { mapState } from 'vuex';

export default {
	name: 'projectProgressForm',
	data: () => ({
		breakdowns:[],
		project: null,
		create_date: null,
		update_date: null,
		advice_status: null,
		dialogWarnning: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		overlay: false,
		managerUpdateArray: []
	}),
	props: {
		projectId: {
			type: Number,
			required: true,
		},
		adviceId: {
			type: Number,
			required: true,
		},
	},
	watch: {
		adviceId(){
			this.getProgressClaimAdvice();
		}
	},
	computed: {
		...mapState('user', ['user'],),
		isDisabled(){
			return this.advice_status === 2;
		},
		isSavedButtonVisible(){
			if(this.advice_status === 0 ){
				return true;
			} else if(this.advice_status === 1 && this.user && this.user.role === 'admin'){
				return true;
			} else if (this.advice_status === 2) {
				return false;
			} else {
				return false;
			}
		}
	},
	filters: {
		currencyFormat(value){
			return '$ ' + parseFloat(value).toFixed(2);
		}
	},
	created(){
			this.getProgressClaimAdvice();
	},
	methods: {
		addNewGroup(bIndex){
				let group = {
					name: "",
					items: []
				}
				this.breakdowns[bIndex].groups.push(group);
		},
		updateManagerArray(newNumber, itemId){
			let isItemExist = this.managerUpdateArray.filter(obj=>{
				return obj.id === itemId;
			});
			if(isItemExist.length) {
				this.managerUpdateArray.forEach(obj=>{
					if(obj.id === itemId){
						obj.completion_percentages = parseFloat(( parseInt(newNumber) / 100 ).toFixed(2));
					}
				})
			} else {
			let tempObj = {
						id: itemId,
						completion_percentages: parseFloat( ( parseInt(newNumber) / 100 ).toFixed(2))
					}
					this.managerUpdateArray.push(tempObj);
			}
		},
		removeGroup(bIndex, gIndex){
			this.breakdowns[bIndex].groups.splice(gIndex, 1);
		},
		addGroupItem(bIndex, gIndex){
			let newItem = {
					name: "",
					complete_percentage: 0,
					total_value: 0
			}
			this.breakdowns[bIndex].groups[gIndex].items.push(newItem);
		},
		removeGroupItem(bIndex, gIndex, itemIndex){
			this.breakdowns[bIndex].groups[gIndex].items.splice(itemIndex, 1);
		},
		onRequestError(message){
			this.snackbar.message = message;
			this.snackbar.color = 'error';
			setTimeout(()=>{
				this.overlay = false;
				this.snackbar.model = true;
			}, 1000)
		},
		addNewBreakdown(){
			let newBreakdown = {
				name: "",
				groups: []
			}	
			this.breakdowns.push(newBreakdown);
		},
		removeBreakdown(bIndex){
			this.breakdowns.splice(bIndex, 1);
		},
		async getProgressClaimAdvice(){
			try {
				let response = await ProgressClaimAdviceService.getProgressClaimAdvice(this.adviceId);
				if(response.data.statusCode === 200){		
					this.project = response.data.data.project;
					this.breakdowns = response.data.data.breakdowns;

					this.breakdowns.forEach(breakdown=>{
						breakdown.groups.forEach(group=>{
							group.items.forEach(item=>{
								item.completion_percentages = item.completion_percentages * 100;
							})
						})
					})

					this.create_date = response.data.data.created_at;
					this.update_date = response.data.data.updated_at;
					this.advice_status = response.data.data.status;
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		},
		updateBreakdown(){
			let invalidRow = 0
			this.breakdowns.forEach(breakdown=>{
				if(breakdown.name.trim() === ""){
					invalidRow ++;
				}
				breakdown.groups.forEach(group=>{
					if(group.name.trim() === ""){
						invalidRow ++;
					}
					group.items.forEach(item=>{
						if(item.name.trim() === ""){
							invalidRow ++;
						}
					})
				})
			})

			this.overlay = true;
			if(invalidRow === 0){
				this.breakdowns.forEach(breakdown=>{
					breakdown.groups.forEach(group=>{
						group.items.forEach(item=>{
							let completion_percentages = parseFloat( parseInt(item.completion_percentages) / 100 ).toFixed(2);
							item.completion_percentages = parseFloat(completion_percentages);
						})
					})
				})

				if(this.user && this.user.role === 'admin'){
					this.updateBreakdownByAdmin();
				} else {
					this.updateBreakdownByManager();
				}

			} else {
					this.snackbar.message = `请确保所有表格内容填写完整且不能为空。`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
					}, 500)
			}
		},
		async updateBreakdownByManager(){
				try {
					let response = await ProgressClaimAdviceService.updateItemProgress(this.adviceId, this.managerUpdateArray);
					if(response.data.statusCode === 200){
						this.project = response.data.data.project;
						this.breakdowns = response.data.data.breakdowns;

						this.breakdowns.forEach(breakdown=>{
							breakdown.groups.forEach(group=>{
								group.items.forEach(item=>{
									item.completion_percentages = item.completion_percentages * 100;
								})
							})
						})

						this.create_date = response.data.data.created_at;
						this.update_date = response.data.data.updated_at;
						this.advice_status = response.data.data.status;

						this.snackbar.message = `进度表更新成功。`;
						this.snackbar.color = 'success';
						setTimeout(()=>{
							this.overlay = false;
							this.snackbar.model = true;
						}, 500)
					}
				} catch(error){
					this.onRequestError(`错误信息：${error}`);
				}
		},
		async updateBreakdownByAdmin(){
				try {
					let response = await ProgressClaimAdviceService.updateProgressClaimAdviceDetail(this.adviceId, this.breakdowns);
					if(response.data.statusCode === 200){
						this.project = response.data.data.project;
						this.breakdowns = response.data.data.breakdowns;

						this.breakdowns.forEach(breakdown=>{
							breakdown.groups.forEach(group=>{
								group.items.forEach(item=>{
									item.completion_percentages = item.completion_percentages * 100;
								})
							})
						})

						this.create_date = response.data.data.created_at;
						this.update_date = response.data.data.updated_at;
						this.advice_status = response.data.data.status;

						this.snackbar.message = `进度表更新成功。`;
						this.snackbar.color = 'success';
						setTimeout(()=>{
							this.overlay = false;
							this.snackbar.model = true;
						}, 500)
					}
				} catch(error){
					this.onRequestError(`错误信息：${error}`);
				}
		},
		async submitBreakdown(toStatusId){
			try {
				let response = await ProgressClaimAdviceService.updateProgressClaimAdvice(this.adviceId, toStatusId);
				if(response.data.statusCode === 200){
					this.advice_status = response.data.data.status;
					this.snackbar.message = `进度表${response.data.message}`;
					this.snackbar.color = 'success';
					this.dialogWarnning = false;
					setTimeout(()=>{
						this.overlay = false;
						this.snackbar.model = true;
					}, 1000)

					this.$emit('on-status-update');
				}
			} catch(error){
				this.onRequestError(`错误信息：${error}`);
			}
		}
	}
}
</script>
<style scoped>
	.form-input-field {
		border: 1px solid #ccc !important;
		height: 34px;
		line-height: 34px;
		width: 100%;
		padding: 3px 6px;
		border-radius: 2px;
		outline: none;
		background-color: #fff;
	}
	.form-input-field:focus{
		border-color: #5f1985 !important;
	}
	.progressClaimTable{
		border: 1px solid #ddd;
		width: 100%;
		border-collapse: collapse;
	}
	.progressClaimTable th{
		padding: 8px 8px;
		border-collapse: collapse;
		border: 1px solid #ddd;
		font-size: 14px;
	}
	.progressClaimTable td{
		padding: 8px 8px;
		border-collapse: collapse;
		border: 1px solid #ddd;
		font-size: 14px;
	}

	.breakdown {
		margin: 6px 12px 12px 12px;
		overflow: auto;
    	min-width: 530px;
	}
	.breakdown-heading{
		padding: 8px;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
</style>