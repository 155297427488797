var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('div',{staticClass:"d-flex justify-space-between align-center pa-1"},[_c('v-subheader',[(_vm.dateFilter !== null)?[_vm._v(" 项目工作记录 - 显示日期: "+_vm._s(_vm.dateFilter)+" ")]:[_vm._v(" 项目工作记录 ")]],2),_c('div',{staticClass:"d-flex align-center"},[_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),(_vm.dateFilter !== null)?[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.showAllRecords}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-format-list-bulleted-square")]),_vm._v(" 显示全部数据 ")],1)]:[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dlgDate = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-search")]),_vm._v("日期过滤 ")],1)],_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticStyle:{"max-width":"150px"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","small":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" 每页显示 "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',{attrs:{"flat":"","dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updatePagePerItem(item)}}},[_c('v-list-item-subtitle',{staticClass:"body-2"},[_vm._v(_vm._s(item))])],1)}),1)],1)],1)],2)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.timesheets,"items-per-page":_vm.itemsPerPage,"page":_vm.currentPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?[_c('v-chip',{attrs:{"color":"warn","small":""}},[_vm._v(" 待递交 ")])]:(item.status === 1)?[_c('v-chip',{attrs:{"color":"info","small":""}},[_vm._v(" 待审核 ")])]:[_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" 已审核 ")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","elevation":"0","small":"","to":{ name: 'timesheetProject', params: {id:item.id}}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-eye ")]),_vm._v(" 查看信息 ")],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"d-flex justify-center py-2 "},[_c('v-pagination',{attrs:{"length":_vm.totalPages},on:{"input":_vm.onUpdatePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dlgDate),callback:function ($$v) {_vm.dlgDate=$$v},expression:"dlgDate"}},[_c('v-card',[_c('v-date-picker',{staticClass:"pb-6",attrs:{"first-day-of-week":0,"show-current":"","locale":"zh-cn"},model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dlgDate = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.getSelectedDateTimesheet}},[_vm._v(" 选择日期 ")])],1)],1)],1),_c('v-divider')],1)}
var staticRenderFns = []

export { render, staticRenderFns }