import Api from '@/services/Api';

export default {
	createProgressClaimAdvice(projectId){
		return Api().post(`/project/${projectId}/progress_claim_advice`);
	},
	listProgressClaimAdvice(projectId){
		return Api().get(`/project/${projectId}/progress_claim_advice`);
	},
	getProgressClaimAdvice(adviceId){
		return Api().get(`/progress_claim_advice/${adviceId}`);
	},
	duplicateClaimAdvice(adviceId) {
		return Api().post(`/progress_claim_advice/${adviceId}`);
	},
	updateProgressClaimAdvice(adviceId, statusId){
		const body = {
			"status": parseInt(statusId)
		}
		return Api().put(`/progress_claim_advice/${adviceId}`, JSON.stringify(body));
	},
	updateProgressClaimAdviceDetail(adviceId, breakdowns){
		return Api().put(`/progress_claim_advice/${adviceId}/content`, JSON.stringify(breakdowns));
	},
	updateItemProgress(adviceId, body){
		return Api().put(`/progress_claim_advice/${adviceId}/progress`, JSON.stringify(body));
	},
	deleteProgressClaimAdvice(adviceId) {
		return Api().delete(`/progress_claim_advice/${adviceId}`);
	}
}