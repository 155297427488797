<template>
	<div>
		<div class="pa-1 d-flex justify-space-between align-center">
			<v-subheader>项目预算表</v-subheader>
			<v-btn
				color="success"
				text
				elevation="0"
				@click="dialogBudget = true"
				v-if="user && user.role === 'admin'"
			>
				<v-icon left small>mdi-plus</v-icon>
				添加预算
			</v-btn>
		</div>
		<v-divider></v-divider>
		<v-data-table
			:headers="budgetHeader"
			:items="budgets"
			:items-per-page="itemsPerPage"
			hide-default-footer
			>
			<template v-slot:item.shows_in_report="{item}">
				<template v-if="item.shows_in_report">
					<v-icon color="success">mdi-check</v-icon>
				</template>
				<template v-else>
					<v-icon color="error">mdi-close</v-icon>
				</template>
			</template>
			<template v-slot:item.actions="{item}">
				<v-btn color="info" elevation="0" small class="mr-2" @click="editBudgetItem(item)">
					<v-icon small left>mdi-pencil</v-icon>
					编辑
				</v-btn>
				<v-btn color="error" elevation="0" small @click="confrimBudgetDeletion(item)">
					<v-icon small left>mdi-delete</v-icon>
					删除
				</v-btn>
			</template>
		</v-data-table>
		<!--- Edit Budget Dialog --->
		<v-dialog v-model="editDialogBudget" max-width="480">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
			<v-card v-if="activeBudget">
				<v-card-title>编辑{{activeBudget.work_type.name}}预算</v-card-title>
				<v-divider></v-divider>
				<div class="pa-4">
					<v-text-field
						v-model="activeBudget.hours"
						outlined
						dense
						hide-details
						:rules="budgetRules"
						label="预算时间（小时）"
						required
					></v-text-field>
					<v-checkbox
						v-model="activeBudget.shows_in_report"
						label="在报表中显示"
					></v-checkbox>
				</div>
				<v-card-actions class="justify-end px-4 py-3">
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						@click="validate"
						type="submit"
					>
						更新项目预算
					</v-btn>
				</v-card-actions>
			</v-card>
			</v-form>
		</v-dialog>
		<!--- Add Budget Dialog --->
		<v-dialog v-model="dialogBudget" max-width="480">
				<project-budget-form
					:projectId="project_id"
					:existingWorkTypes="existingWorkTypIds"
					@on-close="dialogBudget = false"
					@on-submit-success="onProjectBudgetUpdateComplete"
				/>
		</v-dialog>
		<!--- Deletion Confirmation --->
		<v-dialog
			v-model="dialogWarnning"
			max-width="340"
			>
			<v-card v-if="activeBudget">
				<v-card-title class="headline">
					确定删除?
				</v-card-title>
				<v-card-text>
					<br />
					您确定要删除预算<strong>{{activeBudget.work_type.name}}</strong>吗？
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="success darken-1"
					text
					@click="dialogWarnning = false"
				>
					取消
				</v-btn>
				<v-btn
					color="error"
					elevation="0"
					@click="deleteProjectBudget(activeBudget.id)"
				>
					<v-icon left >mdi-delete</v-icon>
						确定删除
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>	

<script>
import ProjectBudgetService from '../../services/ProjectBudget';
import ProjectBudgetForm from './ProjectBudgetForm.vue';
import { mapState } from 'vuex';

export default {
	name: 'ProjectBudgetList',
	components: {
		ProjectBudgetForm
	},
	data: () => ({
		project_id: null,
		budgets: [],
		budgetHeader: [
			{ text: '编号', value: 'id',align: 'start', },
			{ text: '工种', value: 'work_type.name' },
			{ text: '预算时间(小时)', value: 'hours' },
			{ text: '报表中显示', value: 'shows_in_report' },
	        { text: '功能', value: 'actions', sortable: false, width: 200 }
		],
		itemsPerPage: 999,
		activeBudget: null,
		dialogWarnning: false,
		dialogBudget: false,
		editDialogBudget: false,
		existingWorkTypIds: [],
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		valid: true,
		requiredRules: [
			v => !!v || '必填内容',
		],
		budgetRules: [
			v => (v>= 0  && !isNaN(v)) || `请输入数字。例如：30`,
		],
	}),
	props: {
		projectId: {
			type: Number,
			required: true,
		}
	},
	watch: {
	},
	computed: {
		...mapState('user', ['user'],),
	},
	created(){
		this.project_id = this.projectId;
		this.getProjectBudgetList();
	},
	methods: {
		async getProjectBudgetList() {
			try {
				let response = await ProjectBudgetService.getBudgetList(this.project_id);
				if(response.status === 200){
					this.existingWorkTypIds = [];
					this.budgets = response.data.data;
					this.budgets.forEach(budget=>{
						this.existingWorkTypIds.push(budget.work_type.id);
					})
				}
			} catch(error){
				console.log(error)
			}
		},
		async updateProjectBudget(projectBudgetId, body) {
			try {
				let response = await ProjectBudgetService.updateProjectBudget(projectBudgetId, body);
				if(response.status === 200){
					this.snackbar.message = `预算更新成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getProjectBudgetList();
					}, 1000)
				}
			} catch(error){
				console.log(error)
			}
		},
		async deleteProjectBudget(budgetId){
			try {
				this.dialogWarnning = false;
				let response = ProjectBudgetService.deleteProjectBudget(budgetId);
					this.snackbar.message = `预算删除成功`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.getProjectBudgetList();
					}, 1000)
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.snackbar.model = true;
			}
		},
		validate () {
			this.$refs.form.validate();
		},
		editBudgetItem(budget){
			this.activeBudget = budget;
			this.editDialogBudget = true;
		},
		confrimBudgetDeletion(budget){
			this.activeBudget = budget;
			this.dialogWarnning = true;
		},
		onProjectBudgetUpdateComplete(){
			this.dialogBudget = false;
			this.getProjectBudgetList();
		},
		onFormSubmit(){
			this.overlay = true;
			
			//if field is empty string fall back to 0 hours
			if(this.activeBudget.hours === '') {
				this.activeBudget.hours = 0
			}
			const body = {
				"hours": parseInt(this.activeBudget.hours),
				"shows_in_report": this.activeBudget.shows_in_report
			}
			const projectBudgetId = this.activeBudget.id;
			this.editDialogBudget = false;
			this.updateProjectBudget(projectBudgetId, body);
		},
	}
}
</script>
