<template>
	<div>
		<v-dialog
			ref="dialog"
			v-model="modal"
			:return-value.sync="date"
			persistent
			width="290px"
		>
			<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="date"
				label="选择数据月份"
				readonly
				outlined
				dense
				v-bind="attrs"
				v-on="on"
			></v-text-field>
			</template>
			<v-date-picker
				v-model="date"
				type="month"
				show-current
				locale="zh-cn"
				scrollable
				>
				<v-spacer></v-spacer>
				<v-btn
					text
					color="primary"
					@click="modal = false"
				>
					取消
				</v-btn>
				<v-btn
					text
					color="primary"
					@click="getActivityByDate(date)"
				>
					选择
				</v-btn>
			</v-date-picker>
		</v-dialog>
		<v-divider class="my-2"></v-divider>
		<template v-if="header.length">
			<div class="d-flex justify-end">
				<v-btn color="primary" class="mb-3" text elevation="0" @click="generateReport">
				<v-icon>mdi-pdf-box</v-icon>生成PDF文件
				</v-btn>
			</div>
			<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="true"
				:preview-modal="true"
				:paginate-elements-by-height="1400"
				filename="项目汇总表"
				:pdf-quality="2"
				:html2canvas="html2CanvasObj"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="1100"
				ref="html2Pdf"
			>
				<section slot="pdf-content">
					<br />
					<h1 style="width: 100%;">{{projectName}}项目汇总表</h1>
					<br />
					<div class="body-2">Print Date: {{todayDate}}</div>
					<br />
					<br />
					<br />
						<table style="width:100%;" v-if="header.length">
							<thead class="subtitle-1">
								<tr>
									<th class="text-left"></th>
									<template v-for="(team, index) in header" >
										<th :class=" index % 2 == 0 ? 'text-center': 'text-center background-color-grey'" style="min-width: 240px" :key="team.id">
											{{team.name}} 
										</th>
									</template>
								</tr>
								<tr>
									<th class="text-left">日期</th>
									<template v-for="(team, index) in header" >
									<th :class=" index % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="team.id">
										<table class="sub-timetable">
											<tr>
												<td class="caption">包活人数</td>
												<td class="caption">包活工时数</td>
												<td class="caption">天工人数</td>
												<td class="caption">天工工时数</td>
											</tr>
										</table>
									</th>
									</template>
								</tr>
							</thead>
							<tbody class="body-2">
									<tr  v-for="item in dateRows" :key="item.date">
										<td>{{item.date | formatDate}}</td>
										<template  v-for="(activity, i) in item.activities" >
											<td  :class=" i % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="i">
												<table class="sub-timetable">
													<tr v-if="activity.length">
														<td class="caption">{{activity[0].package.worker_count}}</td>
														<td class="caption">{{activity[0].package.total_hours}}</td>
														<td class="caption">{{activity[0].day_labour.worker_count}}</td>
														<td class="caption">{{activity[0].day_labour.total_hours}}</td>
													</tr>
													<tr v-else>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												</table>
											</td>
										</template>
									</tr>
							</tbody>
							<tfoot>
								<tr>
										<td class="text-left body-2"><strong>总数/ Total</strong></td>
										<template v-for="(team, index) in header" >
										<td :class=" index % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="team.id">
											<table class="sub-timetable">
												<tr>
													<td class="body-2"></td>
													<td class="body-2"><strong>{{team.packageTotalHr}}</strong></td>
													<td class="body-2"></td>
													<td class="body-2"><strong>{{team.dayLabourTotalHr}}</strong></td>
												</tr>
											</table>
										</td>
									</template>
								</tr>
							</tfoot>
						</table>
				</section>
			</vue-html2pdf>

			<div class="timetable-container">
				<table class="timetable"  v-if="header.length">
					<thead class="subtitle-1">
						<tr>
							<th class="text-left" style="min-width:150px"></th>
							<template v-for="(team, index) in header" >
								<th :class=" index % 2 == 0 ? 'text-center': 'text-center background-color-grey'" style="min-width: 240px" :key="team.id">
									{{team.name}}
								</th>
							</template>
						</tr>
						<tr>
							<th class="text-left"  style="min-width:150px">日期</th>
							<template v-for="(team, index) in header" >
							<th :class=" index % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="team.id">
								<table class="sub-timetable">
									<tr>
										<td class="caption">包活人数</td>
										<td class="caption">包活工时数</td>
										<td class="caption">天工人数</td>
										<td class="caption">天工工时数</td>
									</tr>
								</table>
							</th>
							</template>
						</tr>
					</thead>
					<tbody class="body-2">
							<tr  v-for="item in dateRows" :key="item.date">
								<td>{{item.date | formatDate}}</td>
								<template  v-for="(activity, i) in item.activities" >
									<td  :class=" i % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="i">
										<table class="sub-timetable">
											<tr v-if="activity.length">
												<td class="caption">{{activity[0].package.worker_count}}</td>
												<td class="caption">{{activity[0].package.total_hours}}</td>
												<td class="caption">{{activity[0].day_labour.worker_count}}</td>
												<td class="caption">{{activity[0].day_labour.total_hours}}</td>
											</tr>
											<tr v-else>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</table>
									</td>
								</template>
							</tr>
					</tbody>
					<tfoot>
						<tr>
								<td class="text-left body-2"  style="min-width:150px"><strong>总数/ Total</strong></td>
								<template v-for="(team, index) in header" >
								<td :class=" index % 2 == 0 ? 'pa-0': 'pa-0 background-color-grey'" :key="team.id">
									<table class="sub-timetable">
										<tr>
											<td class="body-2"></td>
											<td class="body-2"><strong>{{team.packageTotalHr}}</strong></td>
											<td class="body-2"></td>
											<td class="body-2"><strong>{{team.dayLabourTotalHr}}</strong></td>
										</tr>
									</table>
								</td>
							</template>
						</tr>
					</tfoot>
				</table>
			</div>
		</template>
	</div>
</template>
<script>
import ReportServices from '../../services/Report';
import VueHtml2pdf from 'vue-html2pdf'
import {  parse, format, endOfDay, lastDayOfMonth } from 'date-fns';

export default {
	name: 'ReportProjectActivity',
	data: () => ({
   		date: null,
		menu: false,
		modal: false,
		firstDateOfTheMonth: null,
		lastDateOfTheMonth: null,
		header: [],
		dateRows: [],
		activityArray: [],
		html2CanvasObj: {
			scale: 0.8,
       		useCORS: true
		}
	}),
	props: {
		projectId: {
			type: Number,
			required: true
		},
		projectName: {
			type: String,
			required: false
		}
	},
	components: {
		VueHtml2pdf
	},
	watch: {
	},
	filters: {
		formatDate(value){
			let parseDate = parse(value, 'dd/MM/yyyy', new Date());
			return format(parseDate, 'yyyy年MM月dd日');
		}
	},
	computed: {
		todayDate(){
			return format(endOfDay(new Date()), 'dd/MM/yyyy');
		}
	},
	created(){
	},
	methods: {
		getDateFormat(date){
			let parseDate = parse(date, 'yyyy-MM', new Date());
			let firstDateOfTheMonth = format(parseDate, 'dd/MM/yyyy');
			let lastDateOfTheMonthFormatted = lastDayOfMonth(parseDate);
			let lastDateOfTheMonth = format(lastDateOfTheMonthFormatted, 'dd/MM/yyyy');

			this.firstDateOfTheMonth = firstDateOfTheMonth;
			this.lastDateOfTheMonth = lastDateOfTheMonth;
		},
		getActivityByDate(date){
			this.firstDateOfTheMonth = null;
			this.lastDateOfTheMonth = null;
			this.header = [];
			this.dateRows = [];
			this.activityArray = [];

			this.$refs.dialog.save(date);
			this.modal = false;
			this.getDateFormat(this.date);
			this.getProjectActivityByDate();
		},
		async getProjectActivityByDate(){			
			try {
				let params = {
					"project_id": this.projectId,
					"start": this.firstDateOfTheMonth,
					"end": this.lastDateOfTheMonth,
				};
				let response = await ReportServices.projectActivityReport(params);
				if(response.data.statusCode === 200){
					this.header = response.data.data.work_teams;
					let dates = response.data.data.dates;
					for(const key in dates) {
						let activities = dates[key];
						let tempActivityArray = [];
						this.header.forEach(header=>{
							if(activities.length){
								let activityData = activities.filter(activity=>{
									return  activity.work_team_id == header.id;
								})
								tempActivityArray.push(activityData);
							} else {
								tempActivityArray.push([]);
							}
						})
						//Prepare for total calculation
						activities.forEach(activity=>{
							this.activityArray.push(activity);
						})

						let tempObj = {
							'date': key,
							'activities': tempActivityArray
						}
						this.dateRows.push(tempObj)
					}
					this.header.forEach(team=>{
						let dayLabourHrTotal = 0;
						let packageHrTotal = 0;
						this.activityArray.forEach(activity=>{
							if(activity.work_team_id === team.id){
								dayLabourHrTotal = dayLabourHrTotal + activity.day_labour.total_hours;
								packageHrTotal = packageHrTotal + activity.package.total_hours;
							}
						})
						team.dayLabourTotalHr = dayLabourHrTotal;
						team.packageTotalHr = packageHrTotal;
					})

				}
			} catch(error){
				console.log(error)
			}
		},
		generateReport () {
			this.$refs.html2Pdf.generatePdf()
		},
	}
}
</script>
<style scoped>
.timetable{
	border: 1px solid #ddd;
	width: 100%;
	border-collapse: collapse;
}
.timetable th,
.timetable td{
	border: 1px solid #ccc;
	padding:6px 12px;
}
.sub-timetable{
	border-collapse: collapse;
	border: none;
	height:40px;
	width: 100%;
}
.sub-timetable td{
	border-right: 1px solid #ccc;
	border-left: none;
	border-top: none;
	border-bottom: none;
	min-width: 60px;
	max-width: 60px;
	padding-left: 2px;
	padding-right: 2px;
	text-align: center;
}
.sub-timetable tr > td:last-child{
	border-right: none;
}
.timetable-container{
	max-width: 100%;
	overflow-x: auto;
}
.background-color-grey {
	background-color: #f9f9f9;
}
tfoot{
	border-top:2px solid #999;
}
</style>

