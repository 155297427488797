<template>
	<div>
	<div class="d-flex justify-end">
		<v-btn color="primary" text elevation="0" @click="generateReport">
		<v-icon>mdi-pdf-box</v-icon>生成PDF文件
		</v-btn>
	</div>
	<vue-html2pdf
		:show-layout="false"
		:float-layout="true"
		:enable-download="true"
		:preview-modal="true"
		:paginate-elements-by-height="1400"
		filename="项目预算比报表"
		:pdf-quality="2"
		:manual-pagination="false"
		pdf-format="a4"
		pdf-orientation="landscape"
		pdf-content-width="1100px"
		ref="html2Pdf"
	>
		<section slot="pdf-content">
			<br />
			<h1 style="width: 100%;">{{projectName}}项目预算比报表</h1>
			<br />
			<div class="body-2">Print Date: {{todayDate}}</div>
			<br />
			<br />
			<br />
			<!-- PDF Content Here -->
			<vue-c3 :handler="handler"></vue-c3>
			<v-divider class="mt-6"></v-divider>
			<v-divider class="mt-2"></v-divider>
				<table class="pdf-datatable">
					<thead>
						<tr>
							<td class="text-left">
								工种
							</td>
							<td class="text-left">
								已用时间(小时)
							</td>
							<td class="text-left">
								预算时间(小时)
							</td>
							<td class="text-left">
								剩下时间(小时)
							</td>
						</tr>
					</thead>
					<tbody>
						<tr
						v-for="item in budgets"
						:key="item.id"
						>
						<td><strong>{{ item.work_type.name }}</strong></td>
						<td>{{ item.spent }}</td>
						<td>{{ item.hours }}</td>
						<td>{{ item.hours - item.spent }}</td>
						</tr>
					</tbody>
				</table>
		</section>
	</vue-html2pdf>
		<vue-c3 :handler="handler"></vue-c3>
		<v-divider class="mt-6"></v-divider>
		<v-divider class="mt-2"></v-divider>
			<v-simple-table>
				<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							工种
						</th>
						<th class="text-left">
							已用时间(小时)
						</th>
						<th class="text-left">
							预算时间(小时)
						</th>
						<th class="text-left">
							剩下时间(小时)
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
					v-for="item in budgets"
					:key="item.id"
					>
					<td><strong>{{ item.work_type.name }}</strong></td>
					<td>{{ item.spent }}</td>
					<td>{{ item.hours }}</td>
					<td>{{ item.hours - item.spent }}</td>
					</tr>
				</tbody>
				</template>
			</v-simple-table>
	</div>
</template>
<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import ReportServices from '../../services/Report';
import VueHtml2pdf from 'vue-html2pdf';
import {  endOfDay, format } from 'date-fns';

export default {
	name: 'ReportProjectBudget',
	data: () => ({
		handler: new Vue(),
		budgets: []
	}),
	props: {
		projectId: {
			type: Number,
			required: true
		},
		projectName: {
			type: String,
			required: false
		}
	},
	components: {
		VueC3,
		VueHtml2pdf
	},
	watch: {
	},
	computed: {
		todayDate(){
			return format(endOfDay(new Date()), 'dd/MM/yyyy');
		}
	},
	created(){
		if(this.projectId){
			this.getProjectBudgetReport();
		}
	},
	methods: {
		async getProjectBudgetReport(){
			try {
				let params = {
					"project_id": this.projectId,
				};
				let response = await ReportServices.projectBudgetReport(params);
				if(response.data.statusCode === 200){
					this.budgets = response.data.data.budgets;
					let x = ['x'];
					let spent = ['已用时间(小时)'];
					let hours = ['预算时间(小时)'];
					response.data.data.budgets.forEach(item => {
						x.push(item.work_type.name);
						spent.push(item.spent);
						hours.push(item.hours);
					});

					const options = {
						data: {
							x : 'x',
							columns: [
								x,
								spent,
								hours
							],
							groups: [
								['已用时间(小时)', '预算时间(小时)']
							],
							type: 'bar',
							colors: {
								'已用时间(小时)': '#FFC400',
        					    '预算时间(小时)': '#8BC34A',
							}
						},
							axis: {
								x: {
									type: 'category' // this needed to load string x value
								}
							}
					}
					this.handler.$emit('init', options); 
				}
			} catch(error){
				console.log(error)
			}
		},
		generateReport () {
			this.$refs.html2Pdf.generatePdf()
		},
	},
}
</script>

