<template>
	<v-sheet>
		<div class="d-flex justify-space-between align-center pa-1">
			<v-subheader>			
				<template v-if="dateFilter !== null">
					项目工作记录 - 显示日期: {{dateFilter}} 
				</template>
				<template v-else>
					项目工作记录
				</template>
			</v-subheader>
			<div class="d-flex align-center">
			<v-divider vertical class="mx-3"></v-divider>
			<template v-if="dateFilter !== null">
				<v-btn text color="primary" @click="showAllRecords">
				<v-icon left>mdi-format-list-bulleted-square</v-icon>
					显示全部数据
				</v-btn>
			</template>
			<template v-else>
				<v-btn text color="primary" @click="dlgDate = true">
					<v-icon left>mdi-calendar-search</v-icon>日期过滤
				</v-btn>
			</template>
			<v-divider vertical class="mx-3"></v-divider>
				<div style="max-width:150px;">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
							color="primary"
							text
							small
							tile
							v-bind="attrs"
							v-on="on"
							>
								每页显示 {{itemsPerPage}}
							<v-icon small>
								mdi-menu-down
							</v-icon>
							</v-btn>
						</template>
						<v-list flat dense>
							<v-list-item
								v-for="(item, index) in items"
								:key="index"
								@click="updatePagePerItem(item)"
								>
							<v-list-item-subtitle class="body-2">{{ item }}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</div>
		<v-divider></v-divider>		
		<v-data-table
			:headers="headers"
			:items="timesheets"
			:items-per-page="itemsPerPage"
			:page.sync="currentPage"
			hide-default-footer
			>
			<template v-slot:item.status="{item}">
					<template v-if="item.status === 0">
						<v-chip
							color="warn"
							small
							>
							待递交
						</v-chip>
					</template>
					<template v-else-if="item.status === 1">
						<v-chip
							color="info"
							small
							>
							待审核
						</v-chip>
					</template>
					<template v-else>
						<v-chip
							color="success"
							small
							>
							已审核
						</v-chip>
					</template>
			</template>
			<template v-slot:item.actions="{item}">
				<v-btn 
					color="primary"
					elevation="0"
					class="mr-2"
					small
					:to="{ name: 'timesheetProject', params: {id:item.id}}"
				>
					<v-icon small left>
						mdi-eye
					</v-icon>
					查看信息
				</v-btn>
			</template>
		</v-data-table>
		<v-divider></v-divider>
		<div class="d-flex justify-center py-2 ">
			<v-pagination v-model="currentPage" :length="totalPages" @input="onUpdatePage"></v-pagination>
		</div>
		<!--- Datepicker Dialog ---->
		<v-dialog v-model="dlgDate" max-width="290">
			<v-card>
				<v-date-picker
					v-model="datePicker"
					:first-day-of-week="0"
					show-current
					locale="zh-cn"
					class="pb-6"
					>
				</v-date-picker>
				<v-card-actions class="justify-end">
					<v-btn text color="error" @click="dlgDate = false">
						取消
					</v-btn>
					<v-btn color="primary" elevation="0" @click="getSelectedDateTimesheet">
						选择日期
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-divider></v-divider>
	</v-sheet>
</template>

<script>
import TimesheetService from '../../services/Timesheet';
import { format, parse } from 'date-fns';

export default {
	name: 'ProjectTimesheetList',
	data: () => ({
		timesheets: [],
		itemsPerPage: 10,
		totalPages: 1,
		items: [5, 10, 20, 30],
		currentPage: 1,
		status: 0,
		headers: [
			{ text: '日期', value: 'date',align: 'start',sortable: false, },
			{ text: '项目', value: 'project.name' },
			{ text: '状态', value: 'status' },
	        { text: '功能', value: 'actions', sortable: false, width: 120 }
		],
		dlgDate: false,
		datePicker: new Date().toISOString().substr(0, 10),
		dateFilter: null
	}),
	props: {
		projectId: {
			type: Number,
			required: false,
		}
	},
	watch: {
	},
	computed: {
	},
	created(){
		this.getProjectTimesheetList();
	},
	methods: {
		async getProjectTimesheetList(date){
			try {
				let params = {
					"project_id": this.projectId,
					"page": this.currentPage,
					"per_page": this.itemsPerPage
				};

				if(date) {
					params.date = date;
				}

				let response = await TimesheetService.listTimesheetDayEntry(params);
				if(response.data.statusCode === 200){
					this.timesheets = response.data.data.items;
					this.itemsPerPage = response.data.data.max_items_per_page;
					this.totalPages = response.data.data.total_pages;
					this.currentPage = response.data.data.current_page;
				}
			} catch(error) {
				this.$emit('on-error', `错误信息：${error.data.message}`);
			}
		},
		getSelectedDateTimesheet(){
			let formatDate = parse(this.datePicker, 'yyyy-MM-dd', new Date());
			this.dateFilter = format(formatDate, 'dd/MM/yyyy');
			this.getProjectTimesheetList(this.dateFilter);
			this.dlgDate = false;
		},
		showAllRecords(){
			this.dateFilter = null;
			this.getProjectTimesheetList();
		},
		updatePagePerItem(item){
			this.itemsPerPage = parseInt(item, 10)
			this.currentPage = 1;
			this.getProjectTimesheetList();
		},
		onUpdatePage(page){
			this.currentPage = page;
			this.getProjectTimesheetList();
		}
	}
}
</script>
