import Api from '@/services/Api';

export default {
	projectWorkTypeReport(params) {
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/report/project/worktype?${queryString}`);
	},
	projectBudgetReport(params) {
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/report/project/budget?${queryString}`);
	},
	projectWorkTeamSummaryReport(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/report/project/workteam?${queryString}`);
	},
	projectWorkTeamBreakdownReport(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/report/project/workteam/breakdown?${queryString}`);
	},
	projectActivityReport(params){
		let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
		return Api().get(`/report/project/workteam/detail?${queryString}`);
	}
}